import { useBotMessageInput } from "@/chat/channel/BotSuggestionsContext"
import AiApi from "@/common/AiApi"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import RestfulUtil from "@/core/restful/RestfulUtil"
import FileIcon from "@/core/ui/iconsax/linear/document-text.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useTrackAiActivity from "@/reporting/useTrackAIActivity"
import { CreateMessageWithAIQueryParams } from "@components/chat/channel/CreateMessageWithAIButton"
import ChatChannelQuotedMessagePreview from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessagePreview"
import ChatChannelDetailFooterSendButton from "@components/chat/channel/detail/send-button/ChatChannelDetailFooterSendButton"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import MessageAttachContentBlockButton from "@components/chat/channel/message-input/MessageAttachContentBlockButton"
import MessageInputAutoComplete from "@components/chat/channel/message-input/MessageInputAutoComplete"
import { ChatChannelMessageInputQuery } from "@components/chat/channel/message-input/__generated__/ChatChannelMessageInputQuery.graphql"
import { ChatChannelUploadsPreview } from "@components/chat/channel/uploads/ChatChannelUploadsPreview"
import { CHAT_CHANNEL_MAX_ROWS } from "@components/chat/channel/util/chatChannelConstants"
import { getUrlAttachments } from "@components/chat/util/chatUtils"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoInputSkeleton,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import classNames from "classnames"
import { ClipboardEvent, useEffect, useState } from "react"
import { FileUploadButton, ImageDropzone } from "react-file-utils"
import { graphql, useLazyLoadQuery } from "react-relay"
import {
  SendButton as DefaultSendButton,
  useChannelStateContext,
  useComponentContext,
  useMessageInputContext,
} from "stream-chat-react"

type Props = {
  chatChannelId: GlobalID
  forThread?: boolean
  isDirectMessage?: boolean
  setAttachBlockData?: () => void
}

function ChatChannelMessageInput(props: Props) {
  const { chatChannelId, forThread = false, isDirectMessage = false } = props
  const { acceptedFiles, multipleUploads, quotedMessage } = useChannelStateContext()
  const [{ aiGid, aiTemplate }, setParams] =
    useQueryParamState<CreateMessageWithAIQueryParams>()

  const isMobile = useIsMobile()
  const activeOrganization = useActiveOrganization()!
  const [showDiscardInputModal, setShowDiscardInputModal] = useState<boolean>(false)
  const trackAiActivity = useTrackAiActivity()

  const {
    cooldownRemaining,
    handleSubmit,
    isUploadEnabled,
    onSelectEmoji,
    maxFilesLeft,
    uploadNewFiles,
    parent,
    attachments,
    onPaste,
    setText,
    text,
  } = useMessageInputContext()

  useBotMessageInput({
    threadId: parent?.id,
    // Only use bot in threads
    disabled: !forThread,
  })

  const { node } = useLazyLoadQuery<ChatChannelMessageInputQuery>(
    graphql`
      query ChatChannelMessageInputQuery($id: ID!) {
        node(id: $id) {
          ... on ChatChannel {
            __typename
            id
            organization {
              products(type: "course") {
                edges {
                  node {
                    id
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: chatChannelId,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )
  const chatChannel = Relay.narrowNodeType(node, "ChatChannel")

  useEffect(() => {
    if (chatChannelId !== aiGid) return

    if (text) {
      setShowDiscardInputModal(true)
    } else {
      handleCreateMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiGid, chatChannelId])

  const classes = useStyles()
  const { SendButton = DefaultSendButton } = useComponentContext()

  if (!chatChannel) return null
  return (
    <>
      <div className={classes.container}>
        <div className={classes.dividerContainer}>
          <DiscoDivider thickness={1} marginTop={0} marginBottom={0} />
        </div>

        <div
          className={classNames(classes.root, "str-chat__input-flat", {
            "direct-message": isDirectMessage,
            "str-chat__input-flat--send-button-active": SendButton,
            "str-chat__input-flat-quoted": quotedMessage,
          })}
          data-testid={"ChatChannelMessageInput.container"}
        >
          <ImageDropzone
            accept={acceptedFiles}
            disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
            handleFiles={uploadNewFiles}
            maxNumberOfFiles={maxFilesLeft}
            multiple={multipleUploads}
          >
            {/* Quote Preview */}
            {quotedMessage && !forThread && (
              <ChatChannelQuotedMessagePreview quotedMessage={quotedMessage} />
            )}

            <div className={"str-chat__input-flat-wrapper"}>
              <div className={"str-chat__input-flat--textarea-wrapper"}>
                {isUploadEnabled && <ChatChannelUploadsPreview />}

                {/* Message */}
                <div className={classes.input}>
                  <div className={classes.inputMessage}>
                    <MessageInputAutoComplete
                      onPaste={handlePaste}
                      placeholder={"Type a message"}
                      rows={1}
                      maxRows={CHAT_CHANNEL_MAX_ROWS}
                      grow
                      value={text}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </div>
                <div className={classes.actionsContainer}>
                  {/* Actions */}
                  <div className={classes.inputActions}>
                    <MessageAttachContentBlockButton
                      setText={setText}
                      attachments={attachments}
                      text={text}
                      classes={classes.iconButton}
                    />
                    {/* Emoji Picker */}
                    {!isMobile && (
                      <ChatChannelEmojiPickerDropdown
                        onEmojiSelect={onSelectEmoji}
                        classes={classes.iconButton}
                      />
                    )}

                    {isUploadEnabled && !cooldownRemaining && (
                      <DiscoTooltip content={"Files"}>
                        <div data-testid={"fileinput"}>
                          <FileUploadButton
                            accepts={acceptedFiles}
                            disabled={maxFilesLeft === 0}
                            handleFiles={uploadNewFiles}
                            multiple={multipleUploads}
                          >
                            <FileIcon />
                          </FileUploadButton>
                        </div>
                      </DiscoTooltip>
                    )}
                    {text.length === 5000 && (
                      <DiscoText
                        variant={"body-sm-600"}
                        color={"error.main"}
                        marginLeft={1.5}
                      >
                        {"Max message limit reached: 5000 characters"}
                      </DiscoText>
                    )}
                  </div>
                  <div>
                    {/* Send */}
                    {!cooldownRemaining && <ChatChannelDetailFooterSendButton />}
                  </div>
                </div>
              </div>
            </div>
          </ImageDropzone>
        </div>
      </div>

      <DiscoWarningModal
        testid={"ChatCHannelMessageInput.discard-input-warning-modal"}
        isOpen={showDiscardInputModal}
        modalContentLabel={"Replace draft with new message"}
        title={`Looks like there's a draft in progress!`}
        description={
          "Do you want keep your existing draft or discard it and replace it with a new message?"
        }
        confirmationButtonProps={{
          onClick: () => {
            handleCreateMessage()
            closeDiscardInputModal()
          },
          children: "Replace message",
        }}
        cancelButtonText={"Keep draft"}
        onCancel={handleKeepDraft}
        onClose={closeDiscardInputModal}
      />
    </>
  )

  function handleKeepDraft() {
    clearQueryParams()
    closeDiscardInputModal()
  }

  function closeDiscardInputModal() {
    setShowDiscardInputModal(false)
  }

  async function handleCreateMessage() {
    if (!aiGid) return
    await RestfulUtil.createStream({
      startStreamCb: (signal) =>
        AiApi.generateMessage(
          { channelId: aiGid, template: aiTemplate ?? "default" },
          { signal }
        ),
      handleText: (chunk) => {
        setText(chunk)
        return true
      },
      onStreamEnd: (data) => {
        if (!data.botResponseId) return

        trackAiActivity({
          kind: "message_suggestion_drafted",
          entityId: data.botResponseId,
        })

        setParams({ aiBrId: data.botResponseId })
      },
    })
    clearQueryParams()
  }

  function clearQueryParams() {
    setParams({ aiGid: undefined, aiTemplate: undefined }, "replace")
  }

  function handlePaste(event: ClipboardEvent<HTMLTextAreaElement>) {
    onPaste(event)
    const pastedText = event.clipboardData.getData("Text")
    const products = Relay.connectionToArray(chatChannel?.organization?.products)
    const urlAttachments = getUrlAttachments(pastedText, activeOrganization, products)
    if (urlAttachments && urlAttachments.length > 0) {
      attachments.push(...urlAttachments) // Add content attachments for pasted urls
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    maxWidth: `${theme.measure.page.contentMaxWidth}px`,
    margin: "0 auto",
    "&.str-chat__input-flat .rfu-file-upload-button": {
      position: "relative",
      top: "auto",
      right: "auto",
      "& svg": {
        fill: "transparent",
        color: theme.palette.groovy.grey[300],
        opacity: 1,
        width: "20px",
        height: "20px",

        "&:hover": {
          color: theme.palette.groovy.grey[500],
        },
      },

      "& label": {
        height: "30px",
        width: "30px",
        borderRadius: "50%",
        padding: "3px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    "&.str-chat__input-flat": {
      padding: theme.spacing(1, 2.5),
      backgroundColor: theme.palette.background.paper,
      display: "list-item",
      listStyleType: "none",

      "&.direct-message": {
        "& .str-chat__textarea > textarea": {
          padding: theme.spacing(1),
          backgroundColor: `${theme.palette.groovy.neutral[100]} !important`,
        },
        "& .str-chat__textarea textarea": {
          width: "unset",
        },
      },
    },

    "&.str-chat__input-flat-wrapper": {
      display: "block",
    },
    "& .str-chat__input-flat--textarea-wrapper": {
      gap: theme.spacing(1.5),
      display: "flex",
      flexDirection: "column",
    },
    "& .str-chat__textarea": {
      display: "flex",
      alignItems: "center",

      "& > textarea": {
        minHeight: "unset",
        height: "auto",
        background: `${theme.palette.groovy.neutral[100]} !important`,
        padding: theme.spacing(1, 2),
        borderRadius: theme.measure.borderRadius.medium,
        color: `${theme.palette.text.primary} !important`,
        ...theme.typography["body-sm"],

        "&:focus": {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.groovy.grey
              : theme.palette.common.white,
          border: "none",
          boxShadow: `inset 0 0 0 2px ${theme.palette.groovy.grey[200]}`,
          ...theme.typography["body-sm"],
        },

        "&::placeholder": {
          ...theme.typography["body-sm"],
          color: theme.palette.groovy.grey[300],
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxHeight: "40px",
        },
      },
    },
    "& .str-chat__textarea__textarea": {
      position: "relative",
      minWidth: "auto",
      width: "calc(100% - 180px)",
      fontSize: "15px",
      lineHeight: "15px",
      border: "1px solid transparent",
      background: theme.palette.groovy.neutral[100],
      overflowY: "auto",
      flexGrow: 1,
    },
  },
  input: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
    gap: theme.spacing(1),
  },
  inputMessage: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputActions: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-end",
    zIndex: theme.zIndex.raise2,
    padding: "5px",
  },
  iconButton: {
    color: theme.palette.groovy.grey[300],

    "&:hover": {
      color: theme.palette.groovy.grey[500],
    },
  },
  dividerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  skeletonInput: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    padding: theme.spacing(4, 2.5, 1),
    alignItems: "flex-end",
  },
}))

export function ChatChannelMessageInputSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.dividerContainer}>
        <DiscoDivider thickness={1} marginTop={0} marginBottom={0} />
      </div>
      <div className={classes.skeletonInput}>
        <DiscoInputSkeleton height={57} style={{ width: "100%" }} />
        <DiscoButtonSkeleton width={"100px"} />
      </div>
    </div>
  )
}

export default ChatChannelMessageInput
