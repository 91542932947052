import { useBotThreadSuggestion } from "@/chat/channel/BotSuggestionsContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import QAReplyIcon from "@assets/disco/icons/ai/ai-reply.svg"
import QASuggestionIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIBanner from "@components/ai/AIBanner"
import AIButton from "@components/ai/AIButton"
import AITag from "@components/ai/AITag"
import AIText from "@components/ai/AIText"
import AITextButton from "@components/ai/AITextButton"
import ChatChannelBotMarkdown from "@components/chat/channel/detail/message/suggestion/ChatChannelBotMarkdown"
import { ChatChannelBotSuggestedMessageFragment$key } from "@components/chat/channel/detail/message/suggestion/__generated__/ChatChannelBotSuggestedMessageFragment.graphql"
import { ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation } from "@components/chat/channel/detail/message/suggestion/__generated__/ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation.graphql"
import { DiscoButton, DiscoText, DiscoTooltip } from "@disco-ui"
import { useEffect, useRef, useState } from "react"
import { graphql, useFragment, useMutation } from "react-relay"
import { useMessageContext } from "stream-chat-react"

interface Props {
  threadId: string
  inThread?: boolean
  botMessageKey: ChatChannelBotSuggestedMessageFragment$key
}

function ChatChannelBotSuggestedMessage(props: Props) {
  const { threadId, inThread = false, botMessageKey } = props
  const { authUser } = useAuthUser({ required: true })
  const classes = useStyles()
  const adminLabel = useLabel("organization_admin")
  const { handleOpenThread } = useMessageContext()
  const { selectSuggestion } = useBotThreadSuggestion({ threadId })

  const responseRef = useRef<HTMLDivElement>(null)
  const [isReadyToScroll, setIsReadyToScroll] = useState(false)

  function handleSeeResponse(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleOpenThread(e)
    setIsReadyToScroll(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      responseRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
      setIsReadyToScroll(false)
    }, 100)
    return () => clearTimeout(timer)
  }, [isReadyToScroll, responseRef])

  const botMessage = useFragment<ChatChannelBotSuggestedMessageFragment$key>(
    graphql`
      fragment ChatChannelBotSuggestedMessageFragment on BotQueuedMessage {
        id
        suggestedMessage
      }
    `,
    botMessageKey
  )

  const [commit, isDismissing] =
    useMutation<ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation>(graphql`
      mutation ChatChannelBotSuggestedMessage_DismissBotSuggestionMutation($id: ID!) {
        dismissBotSuggestion(id: $id) {
          node {
            id @deleteRecord
          }
        }
      }
    `)

  if (!botMessage.suggestedMessage) return null

  if (!inThread) {
    return (
      <div className={classes.root}>
        <DiscoTooltip content={"See SideKick Response"}>
          <span>
            <AITextButton
              leftIcon={
                <>
                  <QAReplyIcon width={24} height={24} />
                  <QASuggestionIcon width={24} height={24} />
                </>
              }
              textVariant={"body-md-600"}
              onClick={handleSeeResponse}
            >
              {"Suggested Response"}
            </AITextButton>
          </span>
        </DiscoTooltip>
      </div>
    )
  }

  return (
    <AIBanner
      noIcon
      classes={{
        banner: classes.banner,
        buttons: classes.bannerButtonContainer,
      }}
      message={
        <div className={classes.suggestedResponse}>
          <ProfileAvatar size={32} userKey={authUser} />
          <div className={classes.suggestedResponseMessage}>
            <div className={classes.messageSender}>
              <DiscoText variant={"body-md-600"}>{authUser.fullName}</DiscoText>
              <AITag className={classes.postingAsYouTag} name={"Posting as you"} />
            </div>
            <ChatChannelBotMarkdown message={botMessage.suggestedMessage} />
          </div>
        </div>
      }
      buttons={
        <>
          <div className={classes.tag}>
            <QASuggestionIcon width={24} height={24} />
            <AIText variant={"body-md-600"}>{"Suggested Response"}</AIText>
          </div>
          <div className={classes.bannerButtons}>
            <DiscoTooltip
              content={`Dismiss this suggestion for all ${adminLabel.plural}`}
            >
              <DiscoButton
                shouldDisplaySpinner={isDismissing}
                size={"small"}
                variant={"outlined"}
                color={"grey"}
                onClick={dismissSuggestion}
              >
                {"Dismiss"}
              </DiscoButton>
            </DiscoTooltip>
            <DiscoTooltip content={"You will be able to edit the message before sending"}>
              <AIButton size={"small"} onClick={selectSuggestion} noIcon>
                {"Edit & Use"}
              </AIButton>
            </DiscoTooltip>
          </div>
        </>
      }
    />
  )

  function dismissSuggestion() {
    commit({
      variables: { id: botMessage.id },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    marginLeft: theme.spacing(5.5),
    borderRadius: Number(theme.measure.borderRadius.big) - 1,
  },
  banner: {
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "column",
    alignItems: "flex-start",
  },
  bannerButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  bannerButtons: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  suggestedResponse: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
  },
  suggestedResponseMessage: {
    minWidth: "90%",
    maxWidth: "100%",
    marginLeft: theme.spacing(2),
  },
  tag: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  messageSender: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  postingAsYouTag: {
    borderRadius: theme.measure.borderRadius.big,
  },
}))

export default ChatChannelBotSuggestedMessage
