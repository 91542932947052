import { useLabel } from "@/core/context/LabelsContext"
import { Product } from "@/product/api/productApiModels"
import ProductUtils from "@/product/util/productUtils"
import { DiscoText, DiscoTextProps } from "@disco-ui"
import { useLayoutEffect } from "react"
import useCountDownTimer, {
  countdownDataToMessage,
} from "../../utils/hook/useCountDownTimer"

interface ProductCountdownStringProps {
  /**
   * If a product is defined, the countdown will be displayed as "Event starts in 10 minutes".
   * When no product is given, the countdown will be displayed as "Releases in 10 minutes".
   */
  product?: Product
  prefixText?: string
  timerDelayInSeconds?: number
  onEnd?: () => void
  /**
   * When no product is defined, can pass the startsAt as a prop to define the startDate for
   * the countdown.
   */
  startsAt?: Date
  hidePrefixText?: boolean
  textProps?: DiscoTextProps
}

function ProductCountdownString({
  product,
  prefixText = "Releases in",
  timerDelayInSeconds = 1,
  onEnd,
  startsAt,
  textProps,
  hidePrefixText = false,
}: ProductCountdownStringProps) {
  const startDate = startsAt || (ProductUtils.getProductStartDateTime(product!) as Date)
  const countDownData = useCountDownTimer(startDate, { cadenceSecs: timerDelayInSeconds })
  const experienceLabel = useLabel("experience")

  useLayoutEffect(() => {
    if (countDownData.delta === 0 && onEnd) {
      onEnd()
    }
  }, [onEnd, countDownData.delta])

  const prefix = hidePrefixText ? "" : `${prefixText} `

  if (product) {
    return (
      <DiscoText {...textProps}>{`${
        product.type === "course" ? experienceLabel.singular : "Event"
      } starts in ${countdownDataToMessage(countDownData)}`}</DiscoText>
    )
  }
  return (
    <DiscoText {...textProps}>{`${prefix}${countdownDataToMessage(
      countDownData
    )}`}</DiscoText>
  )
}

export default ProductCountdownString
