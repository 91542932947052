import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelAnchorMarkdown from "@components/chat/channel/detail/message/suggestion/ChatChannelAnchorMarkdown"
import ChatChannelUserMarkdown from "@components/chat/channel/detail/message/suggestion/ChatChannelUserMarkdown"
import { TestIDProps } from "@utils/typeUtils"
import Markdown from "markdown-to-jsx"

interface ChatChannelBotMarkdownProps extends TestIDProps {
  message: string
}

function ChatChannelBotMarkdown({ message }: ChatChannelBotMarkdownProps) {
  const classes = useStyles()

  return (
    <div className={classes.markdown}>
      <Markdown
        options={{
          overrides: {
            a: {
              component: ChatChannelAnchorMarkdown,
            },
            ChatChannelUserMarkdown: {
              component: ChatChannelUserMarkdown,
            },
          },
        }}
      >
        {message}
      </Markdown>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  markdown: {
    "& span, & p": {
      fontSize: "14px",
      color: theme.palette.text.primary,
    },
  },
}))

export default ChatChannelBotMarkdown
