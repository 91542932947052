import { useLabel } from "@/core/context/LabelsContext"
import DiscoBallIcon from "@/core/ui/images/empty-state-illustrations/disco-ball.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import NewDirectMessageButton from "@components/chat/new-direct-message/NewDirectMessageButton"
import { DiscoButton, DiscoEmptyState, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"

type ChatChannelEmptyProps = {
  state?: "dm" | "list" | "page" | "threads" | "thread" | "channels" | "pinned"
}

function ChatChannelEmpty({ state = "page" }: ChatChannelEmptyProps) {
  const classes = useStyles()
  const permissions = useActiveProductOrOrganizationPermissions()
  const memberLabel = useLabel("product_member")
  const productLabel = useLabel("admin_experience")

  return renderEmptyState()

  function renderEmptyState() {
    switch (state) {
      // DM list
      case "list":
        return (
          <DiscoText
            variant={"body-sm"}
            color={"groovy.neutral.400"}
            marginTop={1}
            marginBottom={1}
            marginLeft={1.5}
          >
            {"No messages"}
          </DiscoText>
        )
      // Threads list
      case "threads":
        return (
          <Grid
            container
            alignContent={"center"}
            justifyContent={"center"}
            className={classes.container}
          >
            <DiscoEmptyState
              testid={"ChatChannelEmpty"}
              icon={<DiscoBallIcon />}
              title={"No threads yet"}
              subtitle={
                "Threads will populate this space when there's interaction with your messages or when you engage with others' messages."
              }
            />
          </Grid>
        )

      // Individual thread message list
      case "thread":
        return (
          <DiscoEmptyState
            testid={"ChatChannelEmpty"}
            icon={<DiscoBallIcon />}
            subtitle={"No replies yet."}
            variant={"compact"}
          />
        )
      case "dm":
        return (
          <Grid
            container
            alignContent={"center"}
            justifyContent={"center"}
            className={classes.container}
          >
            <DiscoEmptyState
              testid={"ChatChannelEmpty"}
              icon={<DiscoBallIcon />}
              title={"No direct messages"}
              subtitle={"Send a direct message by clicking the button below"}
              buttons={
                <NewDirectMessageButton>
                  {(btnProps) => (
                    <DiscoButton {...btnProps} leftIcon={"add"}>
                      {"New Message"}
                    </DiscoButton>
                  )}
                </NewDirectMessageButton>
              }
            />
          </Grid>
        )
      case "channels":
        return (
          <Grid
            container
            alignContent={"center"}
            justifyContent={"center"}
            className={classes.container}
          >
            <DiscoEmptyState
              testid={"ChatChannelEmpty"}
              icon={<DiscoBallIcon />}
              subtitle={
                permissions.has("chat.manage")
                  ? `Channels you create inside your community and ${productLabel.plural} will show up here.`
                  : "You aren't a member of any channels yet."
              }
            />
          </Grid>
        )
      case "pinned":
        return (
          <Grid
            container
            alignContent={"center"}
            justifyContent={"center"}
            className={classes.container}
          >
            <DiscoEmptyState
              testid={"ChatChannelEmpty"}
              icon={<DiscoBallIcon />}
              subtitle={"No pinned messages yet."}
            />
          </Grid>
        )
      // Chat page
      case "page":
      default:
        return (
          <Grid
            container
            alignContent={"center"}
            justifyContent={"center"}
            className={classes.container}
          >
            <DiscoEmptyState
              testid={"ChatChannelEmpty"}
              icon={<DiscoBallIcon />}
              subtitle={
                permissions.has("chat.manage")
                  ? `Help your ${memberLabel.plural} get the conversation going by sharing something here.`
                  : "Sometimes, we just need someone courageous to initiate the conversation."
              }
            />
          </Grid>
        )
    }
  }
}

const useStyles = makeUseStyles({
  container: {
    height: "100%",
  },
})
export default ChatChannelEmpty
