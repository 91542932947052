import Badge from "@/admin/experiences/badges/Badge"
import CommunityBadge from "@/community/CommunityBadge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ChatChannelSelectQuery } from "@components/chat/channel/select/__generated__/ChatChannelSelectQuery.graphql"
import { DiscoInputSkeleton, DiscoSelect, DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useTheme } from "@material-ui/core/styles"
import { TestIDProps } from "@utils/typeUtils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  value: GlobalID | null | undefined
  onChange: (chatChannelId: GlobalID | null) => void
  emptyText?: string
  placeholder?: string
  propertyToUseForValue?: "id" | "externalChannelId"
  autoFocusInput?: boolean
}

function ChatChannelSelect(props: Props) {
  const {
    autoFocusInput = true,
    testid = "ChatChannelSelect",
    propertyToUseForValue = "externalChannelId",
    onChange,
    ...rest
  } = props
  const classes = useStyles()
  const theme = useTheme()

  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<ChatChannelSelectQuery>(
    graphql`
      query ChatChannelSelectQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            chatChannels(includeProductLevel: true, kinds: [custom, default]) {
              edges {
                node {
                  id
                  product {
                    id
                    name
                    badge {
                      ...BadgeFragment
                    }
                  }
                  externalChannelId
                  kind
                  app {
                    customAppTitle
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: activeOrganization.id }
  )

  const data = Relay.connectionToArray(organization?.chatChannels).map((c) => ({
    app: c.app!,
    product: c.product,
    channel: c,
  }))

  return (
    <DiscoSelect
      testid={`${testid}.select`}
      placeholder={"Select a channel"}
      {...rest}
      onChange={handleChangeChannel}
      options={data.map(({ app, channel }) => ({
        value: channel[propertyToUseForValue],
        title: app.customAppTitle!,
        color: theme.palette.groovy.grey[300],
        searchable: [app.customAppTitle],
      }))}
      renderOption={renderOption}
      autoFocusInput={autoFocusInput}
      filterOptions={{ maxVisible: 100 }}
    />
  )
  function renderOption(option: { value: string; title: any }) {
    const channel = data.find((d) => d.channel![propertyToUseForValue] === option.value)
    if (!channel) return null

    return (
      <DiscoDropdownItem
        testid={`${testid}.option.add.${option.title}`}
        title={`#${option.title}`}
        subtitle={
          channel.product ? (
            <div className={classes.product}>
              {channel.product.badge && (
                <Badge badgeKey={channel.product.badge} size={24} />
              )}
              <DiscoText variant={"body-xs-500"} color={"text.secondary"}>
                {channel.product.name}
              </DiscoText>
            </div>
          ) : (
            <div className={classes.product}>
              <CommunityBadge
                testid={`${testid}.badge.${activeOrganization.slug}`}
                organizationKey={activeOrganization}
                size={24}
              />
              <DiscoText variant={"body-xs-500"} color={"text.secondary"}>
                {activeOrganization.name}
              </DiscoText>
            </div>
          )
        }
      />
    )
  }

  function handleChangeChannel(chatChannelId: GlobalID | null) {
    onChange(chatChannelId)
  }
}

const useStyles = makeUseStyles((theme) => ({
  product: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(0.25),
    gap: theme.spacing(1),
  },
}))

function ChatChannelSelectSkeleton() {
  return <DiscoInputSkeleton />
}

export default Relay.withSkeleton({
  component: ChatChannelSelect,
  skeleton: ChatChannelSelectSkeleton,
})
